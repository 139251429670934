<template>
  <div>
    <div style="margin-bottom:20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/warehouse' }">操作仓管理</el-breadcrumb-item>
        <el-breadcrumb-item>操作仓修改</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always" class="edit-container">
      <div class="title">修改操作仓信息</div>
      <div class="container">
        <div class="container__item">
          <div class="ctitle">操作仓编号</div>
          <div>{{tenantId}}</div>
        </div>
        <div class="container__item">
          <div class="ctitle">操作仓名称</div>
          <div style="width: 300px;">
            <el-input v-model="tenantName" placeholder="请输入内容" maxlength="20"></el-input>
          </div>
        </div>
        <div class="container__item">
          <div class="ctitle">操作仓地址</div>
          <div>{{operateCode}}</div>
        </div>
        <div class="container__item">
          <div class="ctitle">米筐是否已有产品</div>
          <div>
            <el-radio-group v-model="isAlreadyExistProduct">
              <el-radio :label="'1'">是</el-radio>
              <el-radio :label="'0'">否</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="btn-group">
        <div class="btn submit" @click="submit">确认修改</div>
        <div class="btn back" @click="back">返回</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
import { onMounted, reactive, toRefs, getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
export default {
  setup() {
    const router = useRouter()
    const store = useStore()
    const { proxy } = getCurrentInstance()
    const state = reactive({
      tenantId: '',
      tenantName: '',
      operateCode: '',
      isAlreadyExistProduct: ''
    })
    onMounted(() => {
      let warehouseInfo = store.state.warehouse.warehouseInfo;
      state.tenantId = warehouseInfo.tenantId;
      state.tenantName = warehouseInfo.tenantName;
      state.operateCode = warehouseInfo.operateCode;
      state.isAlreadyExistProduct = warehouseInfo.isAlreadyExistProduct;
    })
    const submit = () => {
      if (!state.tenantName) {
        ElMessage({
          message: '请输入操作仓名称',
          type: 'error',
        })
        return;
      }
      let params = {
        tenantId: state.tenantId,
        tenantName: state.tenantName,
        isAlreadyExistProduct: state.isAlreadyExistProduct
      }
      proxy.$put('/cts/back/tenantManage/modify', params).then((res) => {
        if (res.code === '200') {
          ElMessage({
            message: `${res.msg}`,
            type: 'success',
          })
          router.push({ path: '/warehouse' })
        }
      }).catch((err) => {

      });
    }
    const back = () => {
      router.push({ path: '/warehouse' })
    }
    return {
      ...toRefs(state),
      submit,
      back
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-container {
  .title {
    font-weight: bold;
  }
  .container {
    padding: 20px 0 0 30px;
    &__item {
      display: flex;
      align-items: center;
      height: 50px;
      .ctitle {
        width: 120px;
        text-align: right;
        margin-right: 30px;
      }
    }
  }
  .btn-group {
    display: flex;
    padding: 30px 0 0 30px;
    .btn {
      width: 100px;
      text-align: center;
      padding: 6px;
      border-radius: 3px;
      cursor: pointer;
      &.submit {
        background: rgb(56, 162, 138);
        color: #fff;
      }
      &.back {
        border: 1px solid #ddd;
        margin-left: 20px;
      }
    }
  }
}
</style>